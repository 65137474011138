import useChatBot from "@/admin/integrations/chat-bot/util/useChatBot"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useHasEntitlement from "@utils/hook/useHasEntitlement"

export default function useCanAccessTrainingSources() {
  const activeOrganization = useActiveOrganization()!
  const { webCrawl } = useFeatureFlags()
  const hasInlineGeneration = useHasEntitlement("ai_content_generation")
  const chatBot = useChatBot()

  return (
    webCrawl &&
    Boolean(chatBot || activeOrganization.isBrainSearchBotEnabled || hasInlineGeneration)
  )
}
